import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Link } from 'gatsby';

import actionbarGif from '../assets/images/actionbar.gif';
import damageGif from '../assets/images/damage.gif';
import statusGif from '../assets/images/status.gif';
import logo from '../assets/images/logo_white.png';
import config from '../../config';
// partner images
import monday from '../assets/images/monday.jpg'
import browserstack from '../assets/images/browserstack.png'
import daily from '../assets/images/daily-dark.png';
import gitlab from '../assets/images/gitlab-logo-white-rgb.png';
import libertas from '../assets/images/libertas.png'
import matomo from '../assets/images/matomo.webp';
import netlify from '../assets/images/netlify-dark.svg';
import ogn from '../assets/images/ogn_flat.png';
import product from '../assets/images/product.png'
import slack2 from '../assets/images/slack2.jpg';
import worldanvil from '../assets/images/world.png';
import lostlorn from '../assets/images/lostlorn_logo.png';

const partners = [
  {
    "name": "GitLab",
    "href": "https://about.gitlab.com/",
    "img": gitlab
  },
  {
    "name": "DailyBot",
    "href": "https://www.dailybot.com/",
    "img": daily
  },
  {
    "name": "Slack",
    "href": "https://www.slack.com/",
    "img": slack2
  },
  {
    "name": "ProductHunt",
    "href": "https://www.producthunt.com/posts/mythic-table?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-mythic-table",
    "img": product
  },
  {
    "name": "Matomo",
    "href": "https://matomo.com/",
    "img": matomo
  },
  {
    "name": "Netlify",
    "href": "https://www.netlify.com/",
    "img": netlify
  },
  {
    "name": "WorldAnvil",
    "href": "https://www.worldanvil.com/",
    "img": worldanvil
  },
  {
    "name": "OGN",
    "href": "https://opengamingnetwork.com/",
    "img": ogn
  },
  {
    "name": "Monday.com",
    "href": "https://mondaycom.grsm.io/mythictable",
    "img": monday
  },
  {
    "name": "BrowserStack",
    "href": "https://www.browserstack.com/",
    "img": browserstack
  },
  {
    "name": "Libertas",
    "href": "https://libertas.gg",
    "img": libertas
  },
  {
    "name": "Lostlorn",
    "href": "https://lostlorngames.com",
    "img": lostlorn
  }
]

const IndexPage = () => (
  <Layout>
    <SEO title="Mythic Table" />
    <section id="banner">
      <div className="inner">
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <p>{config.subHeading}</p>
      </div>
    </section>

    <section id="wrapper">
      <section id="zero" className="wrapper spotlight style3">
        <div className="inner">
          <div className="content">
            <h2 className="major">Adventurers Wanted!</h2>
            <p>
              We are looking for the brave and skilled to join the Mythic Table party. If you're skilled at coding, art,
              design, social media campaigns, or anything else you feel like contributing to the party. Then you should
              click the link below and embark on this grand adventure with us.
            </p>
            <Link to="/quest" className="special"><i className='fas fa-angle-right'></i>Join the Quest!</Link>
          </div>
        </div>
      </section>
    </section>

    <section id="wrapper">
      <section id="one" className="wrapper spotlight alt style1">
        <div className="inner">
          <div className="content">
            <h2 className="major">The Kickstarter Has Funded!</h2>
            <div className="video">
              <iframe src="https://www.youtube.com/embed/eUjAaI5r2HE"
                frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="Kickstarter Funded"
                allowFullScreen></iframe>
            </div>
            <p>
              We’re so excited to announce that Mythic Table funded its first ever
              Kickstarter campaign! This campaign raised funding for our development
              team to bring you a better Mythic Table experience.
            </p>
            <a href="https://www.mythictable.com/kickstarter/" className="special"><i className='fas fa-angle-right'></i>Check it out!</a>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper spotlight style3">
        <div className="inner">
          <div className="content">
            <h3 className="major">Mythic Table's Recent Absence</h3>
            <p>
              An annoucement from Marc addressing the recent quiet period here at Mythic
              Table as well as some plans we have for the future.
            </p>
            <div className="row">
              <a href="/blog/announcement-2024" className="special">
                <i className='fas fa-angle-right'></i>
                We're still here!
              </a>
            </div>
            <h3 className="major">Land Acknowledgement</h3>
            <p>
              The Mythic Table platform allows people from all over the world to connect.
              This digital landscape is new and exciting, but in this time of reconciliation
              we must not forget how we got here. We recognize that the real lands in which
              we work, play, grow, and love must be properly acknowledged.
            </p>
            <div className="row">
              <a href="/land-ack" className="special">
                <i className='fas fa-angle-right'></i>
                Full acknowledgement...
              </a>
            </div>
            <h3 className="major">Statement on Anti-Asian Hate Crimes</h3>
            <p>
              We at Mythic Table stand in sorrow and solidarity with Asian communities
              globally and in particular with those affected by the increasing racist
              attacks in America. We condemn these attacks and the hatred that fuels
              them. We mourn with the global Asian community the lives that were lost
              and continue to dedicate ourselves to fostering a diverse community at
              Mythic Table.
            </p>
            <div className="row">
              <a href="/blog/anti-asian-hate-2021" className="special">
                <i className='fas fa-angle-right'></i>
                Read on...
              </a>
            </div>
            <h3 className="major">The Mythic Table Sleepout for Charity!</h3>
            <p>
              Hey there! Welcome to the Mythic Table Blog! Here we hope to share
              our thoughts and experiences regarding a different subject each week.
              This time we would like to share our experience doing a “sleep out”
              (sleeping out in the open air) for charity.
            </p>
            <p>
              <div className="row">
                <a href="/blog/mythic-table-sleepout-2021" className="special">
                  <i className='fas fa-angle-right'></i>
                  Learn more...
                </a>
              </div>
            </p>
            <h3 className="major">Mythic Table and the Open Gaming Network</h3>
            <p>
              Mythic Table is proud to announce that we have entered into a
              partnership with the Open Gaming Network. This is very exciting
              news, and we look forward to what we can accomplish together.
            </p>
            <p>
              <a href="/ogn" className="special">
                <i className='fas fa-angle-right'></i>
                Check it out...
              </a>
            </p>
          </div>
        </div>
      </section>

      <section id="three" className="wrapper spotlight alt style4">
        <div className="inner">
          <div className="content">
            <h2 className="major">Stay up to date with our Townhalls</h2>
            <div className="video">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/uWQbES_hp34"
                frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="townhalls"
                allowFullScreen></iframe>
            </div>
          </div>
        </div>
      </section>

      <section id="four" className="wrapper spotlight style5">
        <div className="inner">
          <a href="/#" className="image">
            <img src={damageGif} alt="" />
          </a>
          <div className="content">
            <h2 className="major">Be an owner, not a customer</h2>
            <p>
              Mythic Table has opened its source and its books because we want
              you to know that your contribution is important. If you help fund
              Mythic Table or assist in development, you will see exactly how
              your contribution is used. You will also see our plans and
              forecasts, and you will have an opportunity to shape these. We
              believe in being open about everything, and we are proud to have a
              great community with whom to share this platform.
            </p>
            <p>
              <div className="row">
                <a href="/org/now-open/" className="special">
                  <i className='fas fa-angle-right'></i>
                  Learn more about our Open Initiative
                </a>
                <a href="/#footer" className="special">
                  <i className='fas fa-angle-right'></i>
                  Join Now
                </a>
              </div>
            </p>
          </div>
        </div>
      </section>

      <section id="five" className="wrapper spotlight alt style6">
        <div className="inner">
          <a href="/#" className="image">
            <img src={statusGif} alt="" />
          </a>
          <div className="content">
            <h2 className="major">Believe in us!</h2>
            <p>
              We know there are a lot of great options out there and more coming.
              However, Mythic Table is truly unique in not only vision but in our
              philosophy and business model. We want to be your virtual tabletop, and
              we want you to feel like you are part of this project. We want the success of
              this project to set the standard for how apps are developed and how companies
              collaborate with the public. Help us do this. Join the newsletter and
              get active!
            </p>
            <a href="/#footer" className="special">
              <i className='fas fa-angle-right'></i>
              Join Now
            </a>
          </div>
        </div>
      </section>

      <section id="six" className="wrapper style5">
        <div className="inner">
          <h2 className="major">Features</h2>
          <p>
            This is a short list of the features that set Mythic Table apart.
          </p>
          <section className="features">
            <article>
              <a href="/features/actionsmenu/" className="image">
                <img src={actionbarGif} alt="" />
              </a>
              <h3 className="major">Customizable Action Menu</h3>
              <p>
                This nested action menu is customizable and sensitive to the selected actor.
                Add actions as simple as attacks and as complex as spells and inventory items.
              </p>
              <a href="/features/actionsmenu/" className="special">
                <i className='fas fa-angle-right'></i>
                Learn more
              </a>
            </article>
            <article>
              <a href="/features/expressive_ui/" className="image">
                <img src={damageGif} alt="" />
              </a>
              <h3 className="major">Expressive UI</h3>
              <p>
                Our vision is to express everything the player needs to know about the state of
                the game in the play area. The goal is to make sure players are focused on the action
                instead of a variety of windows, tabs, and popups.
              </p>
              <a href="/features/expressive_ui/" className="special">
                <i className='fas fa-angle-right'></i>
                Learn more
              </a>
            </article>
            <article>
              <a href="/features/modifiers/" className="image">
                <img src={statusGif} alt="" />
              </a>
              <h3 className="major">Modifiers</h3>
              <p>
                Game systems like Pathfinder and Dungeons&amp;Dragons have complex modifiers and
                conditions to track. Mythic Table will make this easy by handling them for you.
              </p>
              <a href="/features/modifiers/" className="special">
                <i className='fas fa-angle-right'></i>
                Learn more
              </a>
            </article>
            <article>
              <a href="/features/actionsystem/" className="image">
                <iframe title="youtube-iframe" width="100%" height="320" src="https://www.youtube.com/embed/7jaY3tWmzoY" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </a>
              <h3 className="major">Actions</h3>
              <p>
                Mythic Table streamlines actions while giving the GM the ability to
                quickly adjust the outcomes. Enabling a smooth experience for both
                players and GMs.
              </p>
              <a href="/features/actionsystem/" className="special">
                <i className='fas fa-angle-right'></i>
                Learn more
              </a>
            </article>
          </section>
          <ul className="actions">
            <li>
              <a href="/features/" className="button">
                Browse All
              </a>
            </li>
            <li>
              <a href="/roadmap/" className="button">
                Roadmap
              </a>
            </li>
          </ul>
        </div>
      </section>

      <section id="seven" className="wrapper alt style4">
        <div className="inner">
          <div className="content">
            <h3 className="major">Our partners and providers</h3>
            <div className="box alt">
              <div className="row gtr-uniform">

                {partners.map(({ name, href, img }) => {
                  return (
                    <div className="col-3"><span className="image fit">
                      <a href={href} target="_blank" rel="noopener noreferrer">
                        <div
                          className='partner-banner'
                          title={name}
                          key={name}
                          style={
                            {
                              "backgroundImage": `url( ${img})`,
                              "backgroundRepeat": 'no-repeat',
                              "backgroundPosition": "center",
                              "backgroundSize": "100%"
                            }

                          }>
                        </div>
                      </a>
                    </span></div>
                  )
                })}

              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </Layout>
);

export default IndexPage;
